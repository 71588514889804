.math-solve {
  .math-container {
    position: relative;

    .math-left {
      position: absolute;
      left: 0;
      top: 0;
      width: 66%;
      text-align: center;

      span {
        vertical-align: middle;
      }
    }

    .math-right {
      width: 33%;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.star-rating {
  font-size: 3rem;
  letter-spacing: 3rem;
  text-align: center;
}
