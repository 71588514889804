body,
html {
  height: 100%;
}

.container {
  min-height: 66vh;
}

.height-100 {
  height: 100%;
}

.size-2 {
  font-size: 2rem;
}
