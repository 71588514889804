@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.typewriter {
  font-size: 2rem;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.25rem solid orange;
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 6s steps(40, end), blink-caret 0.75s step-end infinite;
}
